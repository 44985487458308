<template>
  <div v-if="!overlay">
    <div class="maxlayout container container__margin detail" v-if="item">
      <!-- SEARCH sezione per contenere i campi di ricerca -->
      <div class="search bg-grey border-red ico-doc" v-show="!showViewer">
        <div class="search__caption">
          <div class="search__caption__copy">
            <div class="search__caption__copy__searchbox">
              <div class="azioni-top-right-azp">
                <div class="azioni-title-main-azp">
                  <div class="azioni-title-azp-ricerca">
                    <h1 class="hidden-md-and-up">Dettaglio Rivista</h1>
                  </div>
                  <div class="search-box-in-rip clearfix hole">
                    <div class="search-small-icon-hp">
                      <img src="images/ico_search.svg" alt="" />
                    </div>
                    <div class="search-small-right-hp">
                      <div class="search-small-input-hp">
                        <v-text-field placeholder="Cerca per parole" v-on:keyup="keyPress" v-model="searchText" filled dense></v-text-field>
                        <div class="search-small-main-btn-hp">
                          <input type="submit" value="cerca" class="cta red" @click="research()" />
                        </div>
                      </div>
                    </div>
                    <v-checkbox v-model="check_rivista_search" class="check_rivista" label="Cerca nella rivista" color="indigo darken-3" hide-details></v-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="search container bg-grey action-viewer" style="z-index: 3; margin-top: -30px; height: 20px !important" v-show="showViewer">
        <div class="search__caption">
          <div class="search__caption__copy">
            <!-- viene mostrato solo in versione smartphone -->
            <div class="filter results__actions" v-show="notSearching">
              <span
                class="lnk blue"
                style="cursor: pointer"
                @click="
                  showViewer = false
                  fixcss()
                "
                title="Torna ai risultati di ricerca">
                <span class="mdi mdi-chevron-left"></span>
                <span style="font-size: 10pt; font-weight: 700 !important; padding-top: 0px">Torna al dettaglio</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <v-overlay :value="loading_viewer">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-container v-if="showViewer" class="reset-padding expandContainer" style="position: relative">
        <Viewer :key="currentKey" :settings="settings" :rivista="rivista"></Viewer>
      </v-container>

      <div class="filter results__actions">
        <a v-show="notSearching" title="Torna ai risultati di ricerca" v-if="$route.query.fromSearch" class="lnk blue" @click="back()">
          <span class="lnk-icon back"></span>
          <span class="text">Torna ai risultati</span>
        </a>
        <span v-else> </span>
      </div>

      <!-- DETAIL BOOK -->
      <div class="results-list" v-if="!loading && !showViewer">
        <div class="results-list__items">
          <div class="results-list__items__item clearfix">
            <div class="col30 floatL element__image">
              <a class="element__image__action" title="Vai al dettaglio del volume">
                <div class="element__image__label" v-if="item.anno_riv_primo_fascicolo">
                  Disponibile online dal <span>{{ item.anno_riv_primo_fascicolo }}</span>
                </div>
                <v-img
                  width="217"
                  contain
                  style="cursor: pointer"
                  alt="Copertina volume"
                  @click="goToFirstSummary()"
                  v-if="item && item.provvedimento.json && item.provvedimento.json.rivista_cover_url"
                  :src="fixCopertine(item.provvedimento.json.rivista_cover_url)"></v-img>
                <v-img
                  width="217"
                  contain
                  style="cursor: pointer"
                  alt="Copertina volume"
                  @click="goToFirstSummary()"
                  v-else-if="item && item.provvedimento.json && item.provvedimento.json.cover_url"
                  :src="fixCopertine(
                    settings.copertina +
                    '/pdf/' +
                    item.provvedimento.id_doc_master
                      .toString()
                      .substring(item.provvedimento.id_doc_master.toString().length - 3, item.provvedimento.id_doc_master.toString().length) +
                    item.provvedimento.json.cover_url.toString() +
                    '?width=217')
                  "></v-img>
                <v-img
                  v-else-if="item.provvedimento.json.rivista_cover_url"
                  contain
                  style="cursor: pointer"
                  @click="goToFirstSummary()"
                  :src="localImg(item.provvedimento.json.rivista_cover_url)"></v-img>
                <v-img
                  :width="settings.homepage.image.width"
                  contain
                  style="cursor: pointer"
                  @click="goToFirstSummary()"
                  v-else
                  alt="Copertina volume"
                  :src="fixCopertine(settings.configuration.placeholderIMG)"></v-img>
              </a>
              <div class="menu-accordion" v-show="menuAccordion">
                <v-card>
                  <v-list
                    ><!--archiovo, fascicoli-->

                    <v-list-group v-if="item.archivio[0].riv_by_anno && item.archivio[0].riv_by_anno.indici_annuali">
                      <template v-slot:activator>
                        <v-list-item-title>Indici Annuali</v-list-item-title>
                      </template>
                      <v-list-item
                        v-for="value in item.archivio[0].riv_by_anno.indici_annuali"
                        :key="value"
                        link
                        style="width: 100%"
                        :class="{ 'active-link-aside': checkColor2(Object.keys(value)[0]) }"
                        @click="loadIndex(Object.keys(value)[0], value[Object.keys(value)[0]])">
                        <v-list-item-title style="width: 100%">{{ Object.keys(value)[0] }}</v-list-item-title>
                      </v-list-item>
                    </v-list-group>

                    <v-list-group v-if="item.archivio[0].riv_by_anno && item.archivio[0].riv_by_anno.fascicoli">
                      <template v-slot:activator>
                        <v-list-item-title>Fascicoli</v-list-item-title>
                      </template>
                      <v-list-item v-for="value in item.archivio[0].riv_by_anno.fascicoli" :key="value" link style="width: 100%">
                        <v-list-group no-action sub-group style="width: 100%">
                          <template v-slot:activator>
                            <v-list-item-title style="width: 100%">{{ Object.keys(value)[0] }}</v-list-item-title>
                          </template>

                          <v-list-item
                            v-for="fascicolo in value[Object.keys(value)[0]]"
                            :key="fascicolo"
                            link
                            style="width: 100%"
                            @click="loadFascicolo2(fascicolo)"
                            :class="{ 'active-link-aside': checkColor(fascicolo) }">
                            <v-list-item-title
                              >Fascicolo {{ fascicolo.fascicolo1 }}
                              {{ fascicolo.fascicolo2 ? ' - ' + fascicolo.fascicolo2 : '' }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list-group>
                      </v-list-item>
                    </v-list-group>

                    <v-list-group v-if="item.archivio[0].riv_by_anno && item.archivio[0].riv_by_anno.archivio">
                      <template v-slot:activator>
                        <v-list-item-title>Annate Arretrate</v-list-item-title>
                      </template>
                      <v-list-item v-for="value in item.archivio[0].riv_by_anno.archivio" :key="value" link style="width: 100%">
                        <v-list-group no-action sub-group style="width: 100%">
                          <template v-slot:activator>
                            <v-list-item-title style="width: 100%">{{ Object.keys(value)[0] }}</v-list-item-title>
                          </template>

                          <v-list-item
                            v-for="fascicolo in value[Object.keys(value)[0]]"
                            :key="fascicolo"
                            link
                            style="width: 100%"
                            @click="loadFascicolo2(fascicolo)"
                            :class="{ 'active-link-aside': checkColor(fascicolo) }">
                            <v-list-item-title
                              >Fascicolo {{ fascicolo.fascicolo1 }}
                              {{ fascicolo.fascicolo2 ? ' - ' + fascicolo.fascicolo2 : '' }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list-group>
                      </v-list-item>
                    </v-list-group>
                    <v-list-group v-if="item.archivio[0].riv_by_anno && item.archivio[0].riv_by_anno.addenda" :value="checkSon(item.archivio.riv_by_anno.addenda)">
                      <template v-slot:activator>
                        <v-list-item-title>Addende</v-list-item-title>
                      </template>
                      <v-list-item v-for="value in item.archivio.riv_by_anno.addenda" :key="value" link style="width: 100%">
                        <v-list-group no-action sub-group style="width: 100%" :value="checkSiblings(value)">
                          <template v-slot:activator>
                            <v-list-item-title style="width: 100%">{{ Object.keys(value)[0] }}</v-list-item-title>
                          </template>

                          <v-list-item
                            v-for="fascicolo in value[Object.keys(value)[0]]"
                            :key="fascicolo"
                            link
                            :value="checkColor(fascicolo)"
                            style="width: 100%"
                            @click="loadFascicolo2(fascicolo)"
                            :class="{
                              'active-link-aside': checkColor(fascicolo)
                            }"
                            :id="checkColor(fascicolo) ? 'thisOne' : null">
                            <v-list-item-title
                              >Fascicolo
                              {{ fascicolo.fascicolo1 }}
                              {{ fascicolo.fascicolo2 ? ' - ' + fascicolo.fascicolo2 : '' }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list-group>
                      </v-list-item>
                    </v-list-group>

                    <v-list-group v-if="tabRivistaOnline()">
                      <template v-slot:activator>
                        <v-list-item-title>Rivista Online</v-list-item-title>
                      </template>
                      <v-list-item
                        v-for="(value, index) in tabRivistaOnline()"
                        :key="value.titolo"
                        link
                        style="width: 100%"
                        :class="{
                          'active-link-aside': index == rivistaSelectedId
                        }"
                        @click="detailTabRivista(index)">
                        <v-list-item-title>
                          {{ value.titolo }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list-group>
                  </v-list>
                  <div class="grid__actions" v-if="item.proprieta_rivista && item.proprieta_rivista.shop_url && item.utente && item.utente && !item.utente.acquistato">
                    <a class="cta red big" title="Carica altri risultati" :href="item.proprieta_rivista.shop_url" target="_blank" style="margin-top: 15px; width: 100%">ABBONATI</a>
                  </div>
                </v-card>
              </div>
            </div>
            <div class="col70 floatR element__caption">
              <div v-if="rivistaTab && rivistaTab.immagine">
                <img :src="fixCopertine(rivistaTab.immagine)" style="width: 100%; max-height: 300px" />
              </div>
              <div class="element__caption__header">
                <a class="title uppercase"><span class="border-red"></span>{{ item.provvedimento.e_titolo }}</a>
              </div>
              <div class="element__caption__metadata">
                <div class="metadata-row" v-show="false">
                  <span class="metadata">Direttore/i rivista:</span>
                  <span class="metadata__value">{{ item.dettaglio_rivista.direttori }}</span>
                </div>
                <div class="metadata-row">
                  <span class="metadata">Periodicità:</span>
                  <span class="metadata__value">{{ item.proprieta_rivista.periodicita }}</span>
                  <span class="metadata" style="margin-left: 5px">ISSN:</span>
                  <span class="metadata__value">{{ item.proprieta_rivista.issn }}</span>
                </div>
              </div>

              <div class="filter">
                <a
                  title="Info"
                  @click="
                    bookTabs = !bookTabs
                    menuAccordion = false
                  "
                  class="cta red small">
                  <span class="lnk white">Info</span>
                </a>
              </div>

              <!-- BOOK TABS - VERIFICARE L'HTML CORRETTO DEL FRAMEWORK DA INSERIRE -->
              <div class="book-tabs" v-show="bookTabs">
                <v-tabs v-model="tab" light active-class="book-tabs-active">
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab class="pa-0 ma-0" style="min-width: 0px" />

                  <v-tab v-for="val in new_tab_vals" :key="val" dark> {{ val.name }} </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item>
                    <div v-if="showRivistaOnline">
                      <v-progress-circular style="margin-top: 20px" indeterminate v-show="!rivistaTab.tipologie[rivistaSelectedId].hits"></v-progress-circular>
                      <div class="element__caption__header" style="margin-top: 30px; background: #eaebeb; padding: 20px; border-left: 7px solid #a53535">
                        <a class="title uppercase nolink" style="cursor: pointer"> RIVISTA ONLINE - {{ rivistaTab.tipologie[rivistaSelectedId].titolo }} </a>
                      </div>
                      <v-card flat v-if="rivistaTab.tipologie[rivistaSelectedId].hits">
                        <v-card-text>
                          <div class="other-results__items__item" v-for="hit in rivistaTab.tipologie[rivistaSelectedId].hits" :key="hit">
                            <div class="border-red" v-if="hit._source.provvedimento.sottoTitolo">
                              <span class="part">{{ hit._source.provvedimento.sottoTitolo }}</span>
                            </div>
                            <div class="box">
                              <div class="son" style="width: 100%">
                                <b style="font-size: 11pt; float: right">{{ formatDate(hit._source.provvedimento.data) }}</b>
                                <span class="author" style="margin-top: 0px; display: contents; margin-left: 5px" v-for="(author, ind) in hit._source.estremiAutori" :key="author">
                                  <abbr v-if="author.nome && author.cognome" style="margin-right: 5px">
                                    {{ author.nome }} {{ author.cognome }}{{ semicolon(hit._source, ind) }}
                                  </abbr>
                                </span>
                                <h4 style="width: 85%">
                                  <router-link v-if="checkLink(hit._source.provvedimento)" :to="buildUrlNews(hit._source.provvedimento)" class="title">{{
                                    hit._source.provvedimento.titolo
                                  }}</router-link>
                                  <span v-else>{{ hit._source.provvedimento.titolo }}</span>
                                </h4>
                                <div class="metadata__more" v-if="hit._source.abstracts && hit._source.abstracts.abstract">
                                  <p style="text-transform: initial" v-html="getHtml(hit._source.abstracts.abstract)"></p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <v-pagination
                            v-model="rivistaTab.tipologie[rivistaSelectedId].page"
                            :length="Math.round(rivistaTab.tipologie[rivistaSelectedId].total / rivistaTab.page_size)"
                            @input="detailTabRivista(rivistaSelectedId, rivistaTab.tipologie[rivistaSelectedId].page - 1)"></v-pagination>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-tab-item>
                  <v-tab-item v-for="itm in new_tab_vals" :key="itm">
                    <v-card flat v-if="itm.name == 'INDICE'">
                      <v-card-text>
                        <div class="other-results__box">
                          <div class="other-results__items" v-for="doc in itm.content" :key="doc">
                            <div class="bg-grey border-red heading" v-if="doc.is_index == 1 || doc.is_index == true">
                              <h4 class="title" @click="goToFirstSummary()" style="cursor: pointer">
                                <span>{{ item.provvedimento.anno_riv }} - </span>
                                <span>INDICE </span>
                                <span
                                  >Fascicolo
                                  {{ item.provvedimento.fascicolo1 }}
                                  {{ item.provvedimento.fascicolo2 ? ' - ' + item.provvedimento.fascicolo2 : '' }}</span
                                >
                              </h4>
                              <p>
                                <span class="author" v-if="doc.autori[0]">doc.autori[0].nome</span>
                                <span v-if="doc.metatitolo">{{ doc.metatitolo }}</span>
                                <span v-show="false">ISSN: 1234 - 5678 - 1 [TODO]</span>
                                <span v-if="false && doc.pagine">Pagine: {{ doc.pagine }}</span>
                              </p>
                            </div>
                            <div class="other-results__items__item" v-else>
                              <div class="border-red">
                                <span class="part" v-if="doc.rubrica">{{ doc.rubrica }}</span>
                                <!-- qui andrà raggruppato l'array x il campo che ora è editoriale, cosi da seguire il 
                      mockup con i divisor e i blocchi rossi -->
                              </div>
                              <div class="box">
                                <div class="son">
                                  <v-tooltip right v-if="item.utente && !item.utente.acquistato && doc.flag_contenuto != 1">
                                    <template v-slot:activator="{ on, attrs }">
                                      <div class="lock" v-bind="attrs" v-on="on">
                                        <!-- tooltip lucchetto -->
                                      </div>
                                    </template>
                                    <span>Per leggere questo contenuto è necessario acquistarlo</span>
                                  </v-tooltip>

                                  <div>
                                    <span class="author" style="margin-top: 0px; display: contents; margin-left: 5px" v-for="(author, index) in doc.autori" :key="author">
                                      <abbr v-if="author.nome && author.cognome" style="margin-right: 5px">{{ author.nome }} {{ author.cognome }}{{ semicolon(doc, index) }}</abbr>
                                    </span>
                                    <span v-if="doc.metatitolo" class="author" style="margin-top: 0px; font-weight: bold; display: inline; margin-left: 0px">
                                      | {{ doc.metatitolo }}</span
                                    >
                                  </div>
                                  <h4 class="title" @click="openViewer(doc)" v-if="doc.length == 1">
                                    Fascicolo
                                    {{ item.provvedimento.fascicolo1 }} -
                                    {{ item.provvedimento.fascicolo2 ? item.provvedimento.fascicolo2 + ' - ' : '' }}
                                    {{ item.provvedimento.anno_riv }}
                                  </h4>
                                  <h4 class="title" @click="openViewer(doc)" v-else>
                                    {{ doc.descrizione }}
                                  </h4>
                                  <span class="metadata" v-if="doc.pag_fine && doc.pag_inizio">Pagine: {{ fixPages(doc) }}</span>
                                  <a
                                    class="lnk red metadata__lnkmore"
                                    @click="
                                      doc.showAbstractMetadata = !doc.showAbstractMetadata
                                      $forceUpdate()
                                    "
                                    target="_blank"
                                    v-if="doc.abs_ita || doc.abs_eng"
                                    >Abstract</a
                                  >
                                  <div class="metadata__more" v-show="doc.showAbstractMetadata">
                                    <h6 v-if="doc.abs_ita">Ita</h6>
                                    <p v-if="doc.abs_ita" style="text-transform: initial">
                                      {{ doc.abs_ita }}
                                    </p>
                                    <h6 v-if="doc.abs_eng">Eng</h6>
                                    <p v-if="doc.abs_eng" style="text-transform: initial">
                                      {{ doc.abs_eng }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                    <v-card flat v-else-if="!itm.name.includes('RIVISTA')">
                      <v-card-text v-html="itm.content"></v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </div>
          </div>

          <div class="detail-body clearfix">
            <div class="col30 floatL element__image"></div>
            <div class="col70 floatR element__caption"></div>
          </div>
        </div>
      </div>
      <!--<div class="results-list" v-else>
        <v-progress-circular
        style="margin-top: 20px"
        indeterminate
        color="primary"
      ></v-progress-circular>
      </div>-->
    </div>
    <h1 v-else style="text-align: center; margin: 0 auto">
      <v-progress-circular style="margin-top: 20px" indeterminate color="primary"></v-progress-circular>
    </h1>
    <div class="promo bg-grey">
      <div class="maxlayout container">
        <div class="intro">
          <h3>Ti potrebbero interessare anche</h3>
        </div>

        <!-- GRID box per gestire la visualizzazione a griglia dei volumi -->
        <div class="grid clearfix" v-if="mightLike">
          <div class="grid__item mx-auto hidden-sm-and-down" v-for="item in mightLike.es.list" :key="item" @click="detail(item)">
            <a class="grid__item__caption">
              <div class="grid__item__caption__image">
                <v-img
                  v-if="item._source.provvedimento.campiCalcolati.cover_url"
                  width="217"
                  :src="fixCopertine(
                    settings.copertina +
                    '/pdf/' +
                    item._source.provvedimento.idDocMaster
                      .toString()
                      .substring(item._source.provvedimento.idDocMaster.toString().length - 3, item._source.provvedimento.idDocMaster.toString().length) +
                    '/' +
                    item._source.provvedimento.campiCalcolati.cover_url +
                    '?width=217&fit=outside')
                  "
                  :aspect-ratio="0.629"></v-img>
                <v-img v-else width="217" :src="fixCopertine(settings.configuration.placeholderIMG)"></v-img>
              </div>
              <div class="grid__item__caption__text">
                <h4>{{ item._source.provvedimento.eTitolo }}</h4>
                <p>
                  <span style="font-weight: bold; display: block" v-if="false && item._source.autori"
                    >{{ item._source.autori[0].nome.substring(0, 1) }}. {{ item._source.autori[0].cognome }}</span
                  >
                  <span style="font-weight: bold" v-if="false && item._source.autori && item._source.autori[1]"
                    >{{ item._source.autori[1].nome.substring(0, 1) }}. {{ item._source.autori[1].cognome }}</span
                  >
                </p>
              </div>
            </a>
          </div>

          <v-card elevation="24" max-width="444" class="mx-auto hidden-md-and-up" style="width: 217px; !important">
            <v-carousel v-model="model" hide-delimiters @change="carousel(item)" class="carousel">
              <v-carousel-item v-for="item in mightLike.es.list" :key="item" @click="detail(item)" class="corousel__pagination-number">
                <v-img
                  v-if="item._source.provvedimento.campiCalcolati.cover_url"
                  width="217"
                  contain
                  :src="fixCopertine(
                    settings.copertina +
                    '/pdf/' +
                    item._source.provvedimento.idDocMaster
                      .toString()
                      .substring(item._source.provvedimento.idDocMaster.toString().length - 3, item._source.provvedimento.idDocMaster.toString().length) +
                    '/' +
                    item._source.provvedimento.campiCalcolati.cover_url +
                    '?width=217&fit=outside')
                  "
                  :aspect-ratio="0.629"></v-img>

                <v-img v-else width="217" :src="fixCopertine(settings.configuration.placeholderIMG)"></v-img>
              </v-carousel-item>
            </v-carousel>
          </v-card>
          <v-row justify="space-around" style="margin-top: 20px" class="mx-auto hidden-md-and-up">
            <div class="grid__item__caption__text">
              <h4>{{ currentItem._source.provvedimento.eTitolo }}</h4>
              <p>
                <span v-if="currentItem._source.provvedimento.collana">{{ currentItem._source.provvedimento.collana.descrizione }}</span>
                <br />
                <span style="font-weight: bold" v-if="false && currentItem._source.autori"
                  >{{ currentItem._source.autori[0].nome.substring(0, 1) }}.
                  {{ currentItem._source.autori[0].cognome }}
                </span>
                <span style="font-weight: bold" v-if="false && currentItem._source.autori && currentItem._source.autori[1]"
                  >{{ currentItem._source.autori[1].nome.substring(0, 1) }}. {{ currentItem._source.autori[1].cognome }}</span
                >
              </p>
            </div>
          </v-row>
        </div>

        <!-- in versione smartphone invece del div GRID verrà mostrato il div con il carosello immagini -->
        <div class="carousel">
          <div class="corousel__pagination-bullets"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import axios from 'axios'
  import configuration from '../configuration_variables.json'
  import viewer from '../components/Viewer'
  import GQL from '../services/gql'
  import moment from 'moment'

  export default {
    props: {
      authorization: {
        type: Object
      },
      overlay: {
        type: Boolean
      },
      settings: {
        type: Object
      }
    },
    components: {
      Viewer: viewer
    },
    computed: {
      ...mapState(['config'])
    },
    data: (vm) => ({
      item: null,
      rivista: {},
      admins: [
        ['Management', 'mdi-account-multiple-outline'],
        ['Settings', 'mdi-cog-outline']
      ],
      cruds: [
        ['Create', 'mdi-plus-outline'],
        ['Read', 'mdi-file-outline'],
        ['Update', 'mdi-update'],
        ['Delete', 'mdi-delete']
      ],
      previews: [],
      catalogue: null,
      loading_viewer: false,
      showFavBtn: false,
      bookTabs: true,
      menuAccordion: true,
      showUnlimitedBtn: false,
      is_in_preferito: true,
      showModalError: false,
      showAbstractMetadata: false,
      showUnlimitedBtnFake: false,
      tok: null,
      currentKey: 'viewer',
      showUnlimitedBtnDelete: false,
      chapters: [],
      loading: true,
      showMoreAuthors: false,
      loadingAdd: false,
      expand: true,
      loadingDelete: false,
      stopLoad: false,
      showViewer: false,
      hasEntities: false,
      notSearching: true,
      filtersTypeResearch: ['Tutto il Catalogo', 'I miei volumi', 'Unlimited'],
      tab: 1,
      subTab: null,
      placeholder: 'Cerca in tutto il Catalogo GFL',
      currentItem: null,
      page: null,
      tabss: [
        {
          title: 'INDICE',
          text: 'aa'
        },
        {
          title: 'ABSTRACT',
          text: 'bb'
        }
      ],
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      rules: {
        min: (value) => vm.check(value, true),
        max: (value) => vm.check(value, false)
      },
      typeResearch: 'Tutto il Catalogo',
      list: [],
      showResAdv: false,
      mightLike: null,
      materiaSelected: {
        descrizione: 'Tutto il Catalogo'
      },
      nlp: null,
      oneTime: true,
      new_tab_vals: [],
      tab_vals: [
        {
          name: 'INDICE',
          content: null
        },
        {
          name: 'PRESENTAZIONE',
          content: null
        },
        {
          name: 'CODICE ETICO',
          content: null
        },
        {
          name: 'DIREZIONE E REDAZIONE',
          content: null
        }
      ],
      currentOrder: {
        id: 1,
        name: 'Più pertinente',
        sort: {
          name: '_score',
          order: 'desc'
        }
      },
      orders: [
        {
          id: 1,
          name: 'Più pertinente',
          sort: {
            name: '_score',
            order: 'desc'
          }
        },
        {
          id: 2,
          name: 'Più recente',
          sort: {
            name: 'provvedimento.data',
            order: 'desc'
          }
        }
      ],
      rivistaTab: null,
      rivistaSelectedId: null,
      showRivistaOnline: false
    }),
    watch: {
      $route(to, from) {
        if (this.settings.sso && this.$route.query.id) {
          this.item = null
          this.info(this.$route.query.id_doc_master)
          this.$forceUpdate()
          window.scroll(0, 0)
        }
      },
      typeResearch(val, old) {
        if (val == 'Tutto il Catalogo') this.placeholder = 'Cerca in tutto il Catalogo GFL'
        if (val == 'I miei volumi') this.placeholder = 'Cerca ne I miei volumi'
        if (val == 'Unlimited') this.placeholder = 'Cerca in Unlimited'
      },
      tab(val, old) {
        val != 0 ? (this.rivistaSelectedId = null) : null
        val != 0 ? (this.showRivistaOnline = false) : null
      },
      subTab(val, old) {
        this.detailTabRivista(val)
      }
    },
    metaInfo() {
      if (this.item && this.item.provvedimento) {
        var materie = []
        if (this.item.provvedimento.materie) {
          this.item.provvedimento.materie.forEach((element) => {
            materie.push(element.desc)
          })
        }

        var autori = []
        if (this.item.autori) {
          this.item.autori.forEach((element) => {
            autori.push(element.cognome)
          })
        }

        return {
          title: this.item.provvedimento.titolo,
          meta: [
            {
              name: 'description',
              content: this.item.provvedimento.json ? this.item.provvedimento.json.estremo : this.item.provvedimento.id_doc_master
            },
            {
              name: 'keywords',
              content: `${this.item.provvedimento.titolo},${materie.length > 0 ? materie.join(',') : ''},
            ${this.item.provvedimento.id_doc_master}, ${this.item.provvedimento.cod_isbn}, ${autori.length > 0 ? autori.join(',') : ''}`
            },
            {
              property: 'og:title',
              content: this.item.provvedimento.titolo
            },
            {
              property: 'og:url',
              content: location.href
            },
            {
              property: 'og:description',
              content: this.item.provvedimento.json ? this.item.provvedimento.json.estremo : this.item.provvedimento.id_doc_master
            },
            { property: 'og:type', content: 'product' },
            {
              property: 'og:image',
              content: this.item.provvedimento.json
                ? this.settings.copertina +
                  '/pdf/' +
                  this.item.provvedimento.id_doc_master
                    .toString()
                    .substring(this.item.provvedimento.id_doc_master.toString().length - 3, this.item.provvedimento.id_doc_master.toString().length) +
                  this.item.provvedimento.json.cover_url.toString() +
                  '?width=217'
                : ''
            },
            { name: 'robots', content: 'INDEX,FOLLOW' }
          ]
        }
      }
    },
    mounted() {
      //this.$gtm.trackView('pageview', this.$route.path)
      localStorage.magazineCall = false
      this.$emit('checkQSTicket', this.$route.query) //va messo in tutte le viste

      if (true || this.$el._prevClass) {
        this.loading = true

        if (this.authorization.jwt) {
          this.user = this.authorization
          var logged = this.user.profile.roles.find((x) => x == 'pay') ? true : false
          if (logged) {
            //this.showFavBtn = true;
            //successivamente chiamata al dettaglio verifichiamo se acquistato = true allora showFavBtn = true;
          } else {
            this.showFavBtn = false
          }
        }
        if (this.settings.configuration) {
          if (true || this.$el._prevClass) {
            this.$gtm.trackEvent({
              target: 'home',
              action: 'pageview',
              path: this.$route.path,
              crmId: this.authorization && this.authorization.profile && this.authorization.profile.crmId ? this.authorization.profile.crmId : 'free',
              roles: this.authorization && this.authorization.profile && this.authorization.profile.roless ? this.authorization.profile.roless : null,
              session: this.createSessionTimestamp()
            })
          }
          //if(!this.settings.catalogo && this.$route.query.id_doc_master){
          this.loadRivista({ cod_riv: this.$route.params.cod_riv })
          this.youCouldLike()
          //}
        }
        this.checkRules()

        if (this.settings.sso && this.$route.query.id_doc_master) {
          if (this.$route.query.fromSearch) {
            //verifico le entità
            this.searchText = this.$route.query.fromSearch
            this.getEntities(this.$route.query.entities)
          }
          if (this.$route.query.from) {
            //verifico le entità
            this.typeResearch = decodeURIComponent(this.$route.query.from)
          }
        }
      }
    },
    methods: {
      async REQUEST(call, header, method, type, body) {
        var self = this

        self.loading = true
        if (method == 'GET') {
          await axios
            .get(call, { headers: header })
            .then(async (response) => {
              switch (type) {
                case 'detail':
                  self.item = response.data
                  console.log(self.item)
                  self.$options.computed.$metaInfo()

                  self.rivista = {
                    titolo: self.item.provvedimento.e_titolo,
                    anno: 'Anno ' + self.item.provvedimento.anno_riv,
                    fascicolo: 'Fascicolo ' + self.item.provvedimento.fascicolo1 + (self.item.provvedimento.fascicolo2 ? ' - ' + self.item.provvedimento.fascicolo2 : '')
                  }
                  self.tab_vals[1].content = self.translateENF(self.item.dettaglio_rivista.presentazione)
                  self.tab_vals[2].content = self.translateENF(self.item.dettaglio_rivista.codice_etico)
                  self.tab_vals[3].content = self.translateENF(self.item.dettaglio_rivista.direzione_redazione)
                  //preferiti
                  if (self.item.ultimo_fascicolo) {
                    await self.loadFascicolo({ id_doc_master: self.item.ultimo_fascicolo })
                  }
                  self.filterTabs()
                  //google tag
                  self.$gtm.trackEvent({
                    target: 'dettaglio', // Event type [default = 'interaction'] (Optional)
                    action: 'open',
                    crmId: self.authorization && self.authorization.profile && self.authorization.profile.crmId ? self.authorization.profile.crmId : 'free',
                    codVol: self.$route.query.id_doc_master,
                    titolo: self.item.provvedimento.titolo,
                    roles: self.authorization && self.authorization.profile && self.authorization.profile.roless ? self.authorization.profile.roless : null,
                    session: self.createSessionTimestamp()
                  })

                  self.loading = false
                  self.$forceUpdate()
                  break

                case 'detailFascicolo':
                  this.tab_vals[0].content = response.data.documenti
                  console.log(this.tab_vals[0].content)
                  break
                case 'recoverSummaryApart':
                  if (self.item) {
                    self.item.documenti = response.data.documenti
                  } else {
                  }
                  break
              }
            })
            .catch(function (error) {
              switch (type) {
                case 'detail':
                  /*localStorage.token_biblio_riv = null;
                localStorage.token_biblio_riv_jwt = null;
                self.showFavBtn = false;
                self.showUnlimitedBtn = false;
                self.showModalError = true;*/
                  //self.info(self.$route.query.id_doc_master);

                  break
              }
            })
        } else if (method == 'POST') {
          await axios
            .post(call, body, { headers: header })
            .then(function (response) {
              switch (type) {
                case 'list':
                  if (body) {
                    self.catalogue = response.data
                  } else {
                    var temp = []
                    response.data.list.forEach((element) => {
                      var tmp = {
                        _source: {
                          provvedimento: element.provvedimento,
                          autori: element.autori
                        }
                      }
                      /*tmp._source.provvedimento.codVol =
                      tmp._source.provvedimento.id_doc_master;
                    tmp._source.provvedimento.idDocMaster =
                      tmp._source.provvedimento.id_doc_master;
                    tmp._source.provvedimento.campiCalcolati =
                      tmp._source.provvedimento.campo_calcolato;*/
                      temp.push(tmp)
                    })
                    self.catalogue = temp
                  }
                  if (self.catalogue && self.catalogue.length > 0) {
                  } else {
                    self.stopLoad = true
                  }
                  self.$forceUpdate()
                  break
                case 'beLike':
                  self.mightLike = response.data
                  self.currentItem = response.data.es.list[0]

                  self.$forceUpdate()
                  break
                case 'toggleFavorites':
                  self.loading = false
                  self.$forceUpdate()
                  break
                case 'chapters':
                  self.chapters = response.data.list
                  break
                case 'unlimited':
                  self.loadingAdd = false
                  self.showUnlimitedBtn = false
                  self.showUnlimitedBtnDelete = true
                  break
                case 'search':
                  if (self.settings.catalogo) {
                    self.settings.catalogo.search = response.data
                    self.settings.catalogo.typeResearch = self.typeResearch
                    self.settings.catalogo.fromDetail = true
                    self.settings.catalogo.filters = {
                      typeResearch: self.typeResearch
                    }
                  } else {
                    self.settings.catalogo = {
                      search: response.data,
                      materiaSelected: self.materiaSelected,
                      materie: self.materie,
                      typeResearch: self.typeResearch,
                      fromDetail: true,
                      filters: {
                        typeResearch: self.typeResearch
                      }
                    }
                  }

                  self.$router.push({
                    path: '/results',
                    query: { more: true }
                  })

                  break
              }
            })
            .catch(function (error) {
              switch (type) {
                case 'addToFavorites':
                  //se va in errore
                  self.item.provvedimento.is_in_preferito = !self.item.provvedimento.is_in_preferito
              }
            })
        } else {
          await axios
            .delete(call, { data: body, headers: header })
            .then(function (response) {
              switch (type) {
                case 'toggleFavorites':
                  self.loading = false
                  self.$forceUpdate()
                  break
                case 'unlimited':
                  self.loadingDelete = false
                  self.showUnlimitedBtn = true
                  self.showUnlimitedBtnDelete = false
                  break
              }
            })
            .catch(function (error) {
              switch (type) {
                case 'addToFavorites':
                  //se va in errore
                  self.item.provvedimento.is_in_preferito = !self.item.provvedimento.is_in_preferito
              }
            })
        }
      },
      localImg(img) {
        return (document.location.origin + img).replace(/([^:]\/)\/+/g, '$1')
      },
      fixCopertine(uri){
        return uri.replace(/([^:]\/)\/+/g, '$1')
      },
      moment() {
        return moment()
      },
      async loadFascicolo(fascicolo) {
        var header = {
          Authorization: localStorage.token_biblio_riv_jwt
        }

        this.loading = true
        window.scroll(0, 0)
        if (fascicolo.id_doc_master) {
          localStorage.current_year_index = null
          localStorage.current_id_doc_master = fascicolo.id_doc_master
          await this.REQUEST(configuration.apiURL + '/fascicolo/' + fascicolo.id_doc_master + '?attributi=true', header, 'GET', 'detailFascicolo', null)
        }
      },
      loadRivista(rivista) {
        var header = {
          Authorization: localStorage.token_biblio_riv_jwt
        }

        this.loading = true
        window.scroll(0, 0)
        if (rivista.cod_riv) {
          this.REQUEST(configuration.apiURL + '/rivista/' + rivista.cod_riv, header, 'GET', 'detail', null)
        }
      },
      filterTabs() {
        var temp = []
        this.tab_vals.forEach((element) => {
          if (element.name == 'INDICE') temp.push(element)
          else if (element.content && element.content.indexOf('<body>null</body>') < 0 && element.content.indexOf('<body></body>') < 0) temp.push(element)
        })
        this.new_tab_vals = temp
        this.$forceUpdate()
      },
      loadIndex(year, fascicolo) {
        var header = {
          Authorization: localStorage.token_biblio_riv_jwt
        }

        this.loading = true
        window.scroll(0, 0)
        if (fascicolo[0].id_doc_master) {
          localStorage.current_year_index = year
          localStorage.current_id_doc_master = fascicolo[0].id_doc_master
          this.REQUEST(configuration.apiURL + '/fascicolo/' + fascicolo[0].id_doc_master + '?attributi=true&soloindice=true', header, 'GET', 'detail', null)
        }
      },
      loadFascicolo2(fascicolo) {
        var header = {
          Authorization: localStorage.token_biblio_riv_jwt
        }

        this.loading = true
        window.scroll(0, 0)
        if (fascicolo.id_doc_master) {
          this.$router
            .push({
              path: '/details',
              query: { id_doc_master: fascicolo.id_doc_master }
            })
            .catch((err) => {})
        }
      },
      translateENF(content) {
        var final = content
        var doc = new DOMParser().parseFromString(content, 'text/html')
        var enf = doc.querySelectorAll('enf')
        enf.forEach((el) => {
          if (el.attributes[0].nodeValue == 'grassetto') {
            el.style.fontWeight = 'bold'
          }
          if (el.attributes[0].nodeValue == 'corsivo') {
            el.style.fontStyle = 'italic'
          }
          if (el.attributes[0].nodeValue == 'sottolineato') {
            el.style.textDecoration = 'underline'
          }
          //
        })
        final = doc
        final = final.documentElement.innerHTML
        return final
      },
      fixcss() {
        document.getElementsByClassName('maxlayout')[0].style.maxWidth = '1240px'
      },
      back() {
        if (this.settings.catalogo && this.settings.catalogo.search && this.$route.query.fromSearch.length > 0) {
          this.settings.catalogo.comingBack = true

          this.$router.push({
            path: '/results'
          })
        } else {
          /*this.settings.catalogo = null;*/
          this.settings.comingBack = true
          this.$router.push({
            path: '/results?more=true'
          })
        }
      },
      fixPages(doc) {
        if (doc.pag_fine && doc.pag_inizio) return parseInt(doc.pag_fine) - parseInt(doc.pag_inizio)
        else {
          //console.log("Al doc: " + doc.id_unita_doc + " manca pagina x calcolo");
          if (doc.pag_fine) return doc.pag_fine
          else return doc.pag_inizio
        }
      },
      filtering() {
        this.research()
      },
      goToUnlimited() {
        this.$router.push({ path: '/unlimited' })
      },
      keyPress(e) {
        if (e.keyCode === 13) {
          this.$route.query.more = null
          this.page = 1
          this.removenlp = false
          this.research()
        }
      },
      createSessionTimestamp() {
        var d = new Date()
        var session = d.getFullYear().toString() + d.getMonth().toString() + d.getDate().toString() + d.getHours() + d.getMinutes() + d.getSeconds()
        return session
      },
      youCouldLike() {
        var header = {
          Authorization: localStorage.token_biblio_riv_jwt
        }
        var body = null
        if (this.$route.query.fromSearch) {
          body = {
            text: this.$route.query.fromSearch,
            filters: [],
            sort: {
              name: '_score',
              order: 'desc'
            }
          }
        } else {
          body = {
            filters: [],
            sort: {
              name: 'cronologico',
              order: 'desc'
            }
          }
        }
        var val = this.settings.catalogo
          ? this.settings.catalogo.search.es.list[0]._source.provvedimento.interesse.id.toString()
          : this.settings.homeResults
          ? this.settings.homeResults.interesse.id
          : false
        if (val) {
          body.filters.push({
            name: 'interesse',
            value: parseInt(val)
          })
        }
        body.detail = false
        this.REQUEST(this.settings.configuration.apiURL + '/ricerca/riviste/0/4?nlp=false&jwt_role=false', header, 'POST', 'beLike', body)
      },
      checkRulesNotUnlimited() {
        var role37 = this.authorization.profile.roles.find((x) => x.pos.toString().toLowerCase() == '37')
        var role36 = this.authorization.profile.roles.find((x) => x.pos.toString().toLowerCase() == '36')
        var unl = this.authorization.profile.roles.find((x) => x.pos.toString().toLowerCase() == 'bvunlimited')
        if (role37 && !unl) return true
        //c'era in or anche !role36
        else return false
      },
      getEntities(val) {
        if (val && val == 'true') {
          this.hasEntities = true
        } else {
          this.hasEntities = false
        }
      },
      carousel(item) {
        this.currentItem = this.mightLike.es.list[this.model]
      },
      addToUnlimited(item) {
        var header = {
          Authorization: this.tok
        }
        var body = {
          cod_vol: item.provvedimento.cod_rivista
        }
        this.REQUEST(configuration.apiURL + '/unlimited', header, 'POST', 'unlimited', body)
      },
      checkRules() {
        if (this.user) {
          var role37 = this.user.profile.roles.find((x) => x.pos == 37)
          if (role37) this.filtersTypeResearch = ['Tutto il Catalogo', 'I miei volumi', 'Unlimited']
          else this.filtersTypeResearch = ['Tutto il Catalogo', 'Unlimited']
        } else {
          this.filtersTypeResearch = ['Tutto il Catalogo', 'Unlimited']
        }
      },
      removeFromUnlimited(item) {
        var header = {
          Authorization: localStorage.token_biblio_riv_jwt
        }
        var body = {
          cod_vol: item.provvedimento.cod_rivista
        }
        this.REQUEST(configuration.apiURL + '/unlimited', header, 'DELETE', 'unlimited', body)
      },
      toggleFav(item) {
        //non lo deve vedere se utente free
        var header = {
          Authorization: localStorage.token_biblio_riv_jwt
        }
        var body = {
          idDocMaster: item.provvedimento.id_doc_master
        }
        if (!item.utente || !item.utente.preferito) {
          this.REQUEST(configuration.apiURL + '/preferiti', header, 'DELETE', 'toggleFavorites', body)
        } else {
          this.REQUEST(configuration.apiURL + '/preferiti', header, 'POST', 'toggleFavorites', body)
        }
      },

      recoverSummary(id) {
        var header = {
          Authorization: this.tok
        }
        this.REQUEST(configuration.apiURL + '/volume/sommario/' + id, header, 'GET', 'recoverSummaryApart', null)
      },
      parseJwt(token) {
        var base64Url = token.split('.')[1]
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        var jsonPayload = decodeURIComponent(
          atob(base64)
            .split('')
            .map(function (c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join('')
        )

        return JSON.parse(jsonPayload)
      },
      hasHistory() {
        return window.history?.length > 2
      },
      rndStr(len) {
        let text = ' '
        let chars = 'abcdefghijklmnopqrstuvwxyz'
        for (let i = 0; i < len; i++) {
          text += chars.charAt(Math.floor(Math.random() * chars.length))
        }
        return text
      },
      refreshComponent() {
        this.currentKey = this.rndStr(8)
        this.$forceUpdate()
      },
      openViewer(item) {
        this.loading_viewer = true

        var url = this.settings.pdfviewer ? this.settings.pdfviewer + 'newViewer.html' : this.settings.pdfviewer_express
        if (this.hasEntities) {
          url += '?token=' + item.v_token
        } else {
          if (this.$route.query.fromSearch) {
            url += '?search=' + this.$route.query.fromSearch + '&env=riviste' + '&token=' + item.v_token
          } else {
            url += '?token=' + item.v_token + '&env=riviste'
          }
        }
        //window.open(url, "_blank");
        this.settings.viewerUrl = url
        localStorage.currentPdfViewerURL = url
        this.showViewer = true
        window.scroll(0, 0)
        document.getElementsByClassName('maxlayout')[0].style.maxWidth = 'none'
        this.refreshComponent()
        setTimeout(() => {
          this.loading_viewer = false
        }, 3000)
      },
      goToFirstSummary() {
        this.openViewer(this.item.documenti[0])
      },
      loadPreviews() {
        var header = null
        var body = {
          document: link,
          zoomFactor: 1,
          isFileName: true,
          uniqueId: 'Sync_PdfViewer_test-7699de8d8646',
          action: 'Load',
          elementId: 'pdfViewer'
        }
        this.REQUEST(configuration.apiPDF, header, 'POST', 'preview', body)
      },
      research() {
        var from = 0
        var body = {}
        if ((this.searchText != null && this.searchText.length > 0) || (this.materiaSelected && this.materiaSelected.descrizione.toUpperCase() != 'TUTTO IL CATALOGO')) {
          if (this.materiaSelected && this.materiaSelected.descrizione.toUpperCase() != 'TUTTO IL CATALOGO') {
            body = {
              filters: [{ name: 'materia', value: [this.materiaSelected.id.toString()] }]
            }
          } else {
            body = {
              filters: []
            }
          }
          if (this.searchText) {
            this.more = false
            this.ifMateria = false
            body.text = this.searchText
            //body.sort = { name: "_score", order: "desc" };
            body.sort = { name: '_score', order: 'desc' }
          } else {
            this.ifMateria = true
            this.settings.materiaSelected = this.materiaSelected
            this.more = true
            //body.sort = { name: "provvedimento.data", order: "desc" };
            body.sort = { name: '_score', order: 'desc' }
          }
          var header = {
            Authorization: localStorage.token_biblio_riv_jwt
          }
          this.settings.curentFilter = 'Tutto il Catalogo'
          this.settings.currentSearchWord = this.searchText
          //decommenta quando il servizio sarà pronto, ora risultati statici

          if (this.check_rivista_search) {
            body.filters = [
              {
                name: 'codRiv',
                value: this.item.provvedimento.cod_riv
              }
            ]
          }
          this.notSearching = false

          this.REQUEST(this.settings.configuration.apiURL + '/ricerca/riviste/0/8?nlp=false&jwt_role=false', header, 'POST', 'search', body)

          /*this.$router.push({
        path: "/results"
      });*/
        }
      },
      checkColor(fascicolo) {
        if (localStorage.current_id_doc_master && localStorage.current_id_doc_master == fascicolo.id_doc_master.toString()) {
          return true
        } else return false
      },
      checkColor2(year) {
        if (localStorage.current_year_index && localStorage.current_year_index == year) {
          return true
        } else return false
      },
      detail(item) {
        this.$router
          .push({
            path: '/details',
            query: { id_doc_master: item._source.provvedimento.idDocMaster }
          })
          .catch((err) => {})
        //location.reload();
      },
      tabRivistaOnline() {
        let rivista = this.config.news ? this.config.news.riviste.find((x) => x.codice_rivista == parseInt(this.$route.params.cod_riv)) : null
        if (rivista) {
          this.rivistaTab = rivista
          return rivista.tipologie
        }
        return null
      },
      detailTabRivista(id, page) {
        if (this.rivistaTab) {
          const tok = this.config.news.token
          const url = this.config.news.newton
          const payload = { ...this.rivistaTab.tipologie[id], from: page ? this.rivistaTab.page_size * page : 0, size: this.rivistaTab.page_size }
          return GQL.rivista(url, tok, payload).then((res) => {
            this.$store.dispatch('setRivistaTab', id)
            this.rivistaTab.tipologie[id].hits = res.data.hits.hits
            this.rivistaTab.tipologie[id].total = res.data.hits.total.value
            console.log(this.rivistaTab.tipologie[id])
            if (this.rivistaTab.tipologie[id].total == 1) {
              this.buildUrlNews(this.rivistaTab.tipologie[id].hits[0]._source.provvedimento, true)
            } else {
              this.rivistaSelectedId = id
              this.showRivistaOnline = true
              this.tab = 0
              this.$forceUpdate()
            }
          })
        }
      },
      formatDate(date) {
        if (date) {
          return date.split('T')[0].split('-').reverse().join('/')
        }
        return ''
      },
      semicolon(doc, index) {
        if (doc.autori) {
          return index < doc.autori.length - 1 ? ';' : ''
        } else {
          return index < doc.estremiAutori.length - 1 ? ';' : ''
        }
      },
      checkLink(data) {
        return data.idDocMaster && data.idDatabanks && data.tipologiaDoc
      },
      buildUrlNews(data, direct) {
        if (this.checkLink(data)) {
          let uri = `/news/${this.$route.params.cod_riv}/${data.tipologiaDoc}/${data.idDocMaster}`
          if (direct) {
            this.$router.push({ path: uri }).catch((err) => {})
          } else {
            return uri
          }
        }
      },
      getHtml(html) {
        if (html.length > 300) {
          return html.substring(0, 300) + '...'
        }
        return html
      }
    }
  }
</script>
<style>
  em {
    /*background: gold !important;
  padding: 2px !important;*/
    color: #ba0000;
    font-size: inherit !important;
    font-weight: normal !important;
    font-style: initial !important;
  }

  .prefSwitch * {
    border: none !important;
  }
  .v-image.v-responsive.v-carousel__item.theme--dark,
  .v-window.carousel.v-item-group.theme--dark.v-carousel {
    height: auto !important;
  }

  .cardsVolumi .v-image__image.v-image__image--cover {
    background-position: top !important;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .check_rivista label {
    color: #133371 !important;
    font-weight: bold;
  }

  span.lnk.blue:hover,
  span.lnk.blue {
    text-decoration: none !important;
  }
  .detail .filter {
    margin-top: 5px;
    /*justify-content: space-evenly;*/
  }
  .v-select__selections input,
  .srch input {
    background: transparent !important;
    border: none;
  }
  .wrap_floatL {
    text-align: right;
  }
  .lnk:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  @media (max-width: 767px) {
    .wrap_floatL {
      text-align: center !important;
    }
    .srch {
      width: 100% !important;
      margin-left: -0px !important;
    }
  }
  .nolink:hover {
    cursor: default !important;
    color: #133371 !important;
  }
</style>
